/* SidePanel.css */

.side-panel {
  position: fixed;
  top: 60px; /* Adjusted to match the top bar height */
  left: 0;
  height: calc(100% - 60px); /* Adjusted to cover remaining height */
  width: 250px;
  background-color: #1a1a1a;
  color: #fff;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding-top: 20px; /* Adjusted padding */
}

.side-panel.hide-panel {
  transform: translateX(-250px);
}

.tabs {
  margin-bottom: 20px;
}

.tab {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 16px;
}

.tab:hover {
  background-color: #2c3e50;
}

.selected {
  background-color: #e67e22;
}

.tab-content {
  padding: 10px;
  /* Additional styles for tab content */
  color: #ccc;
}

/* Adjust the content area to avoid overlap */
.grid-content {
  margin-left: 10px; 
}
