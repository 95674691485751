/* AddRowModal.css */
.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  /* Semi-transparent white */
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* ... existing styles ... */

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
  max-width: 500px;
}

input[type="number"]:required::before,
input[type="text"]:required::before {
  content: "!";
  color: #e74c3c;
  /* Red color */
  font-size: 18px;
  position: absolute;
  left: 5px;
  /* Adjust the left position as needed */
  top: 50%;
  transform: translateY(-50%);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

input.required {
  border-color: #e74c3c;
  /* Red border for required fields */
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Show and hide modal */
.show {
  display: block;
}

.hide {
  display: none;
}

/* Input field styles (adjust as needed) */
input[type="text"],
input[type="checkbox"],
input[type="number"],
button {
  margin-top: 4px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ... existing styles ... */

/* Adjusted spacing between input fields */

input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.email-input-container {
  position: relative;
}

.empty-field {
  border-color: red;
  /* Set border color to red for empty field */
}

.error-indicator {
  color: red;
  /* Set text color to red for the error indicator */
  position: absolute;
  /* Position it absolutely within the input field */
  margin-left: 5px;
  /* Adjust margin for proper positioning */
  font-size: 18px;
  /* Set the font size to make it bigger */
  margin-top: 5px;
  /* Move it down a bit */
}

button:hover {
  background-color: #2980b9;
}

/* ... existing styles ... */

/* Is DP and Is Requested checkbox */
.checkbox-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.checkbox-column > div > label {
  font-size: 14px;
}
