/* AddRowModal.css */
.modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .modal-content {
    width: 300px;
  }
  
  .show {
    display: block;
  }
  
  .hide {
    display: none;
  }
  
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
  }
  
  /* FileUploadModal.css */
  #businessDropdown {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  /* Add these styles to your existing styles/UserTable/userTable.css */
  /* ...existing styles... */
  
  /* Additional styles for the custom-file-upload */
  .custom-file-upload {
    cursor: pointer;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .custom-file-upload:hover {
    background-color: #0056b3;
  }
  /* AddRowModal.css */
/* ... existing styles ... */

/* Buttons styles */
.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .modal-buttons button:hover {
    background-color: #007bff; /* Change the color on hover as needed */
    color: #ffffff;
  }
  
  /* ... existing styles ... */
  
  
  /* You may need to adjust these styles based on your design preferences */
  