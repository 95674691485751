@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-react-grids/styles/material.css";
@import "@syncfusion/ej2-react-dropdowns/styles/material.css";
@import "@syncfusion/ej2-react-grids/styles/material.css";
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-buttons/styles/material.css";
/* @import ".@syncfusion/ej2-calendars/styles/material.css"; */
@import "@syncfusion/ej2-dropdowns/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/material.css";
@import "@syncfusion/ej2-navigations/styles/material.css";
@import "@syncfusion/ej2-popups/styles/material.css";
@import "@syncfusion/ej2-splitbuttons/styles/material.css";
@import "@syncfusion/ej2-react-grids/styles/material.css";
.e-flmenu {
  max-height: 350px;
  z-index: 100000;
  width: 250px;
  left: 76px;
  top: 29px;
}
.e-grid .e-altrow {
  background-color: #fafafa;
}
/* Style for the dropdownlist */
.e-input-group.e-popup-flmenu.e-ddl {
  width: 100%;
}

/* Style for the input in the dropdownlist */
.e-flmenu-input.e-control.e-autocomplete.e-lib.e-input.e-keyboard {
  width: 100%;
}
.e-grid .e-rowcell {
  line-height: 26px;
}
/* Style for the filter menu buttons */
.e-footer-content
  .e-filter-popup.e-control.e-btn.e-lib.e-flmenu-okbtn.e-primary.e-flat,
.e-footer-content
  .e-filter-popup.e-control.e-btn.e-lib.e-flmenu-cancelbtn.e-flat {
  margin-top: 10px;
}

/* Basic styles for the grid container */
.e-hide {
  display: none;
}
.e-dlg-container {
  display: none;
}
.grid-container {
  margin-top: 5rem;
}
.e-grid .e-gridcontent table .e-row .e-rowcell {
  font-family: "Public Sans", sans-serif;
}
.upload-button-container {
  display: flex;
  justify-content: flex-end; /* Align content to the right */
  padding: 20px; /* Add padding for spacing */
}

.custom-file-upload {
  border: 2px solid #007bff;
  color: #007bff;
  cursor: pointer;
  display: inline-block;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #fff;
}

.custom-file-upload:hover {
  background-color: rgba(0, 139, 186, 0.066);
}
.custom-font-style {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
}

/* Style for the search input */

/* Style for the search button */

.add-row-button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 10rem;
  margin: 0 !important;
  transition: background-color 0.3s ease-in-out;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

/* Hover effect for column headers */

.custom-checkbox {
  zoom: 1.5; /* Adjust the zoom level as needed */
}

/* Style for search input */
.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Responsive styles for the grid */

/* Styling for Pagination */
.search-input-container {
  position: relative;
  width: 150px;
  margin-right: auto;
}

.search-icon {
  position: absolute;
  right: 10px; /* Adjust the right position as needed */
  top: 50%;
  transform: translateY(-50%);
  color: #999; /* Adjust the color as needed */
}

.searchtext {
  padding-right: 30px; /* Adjust the padding to make space for the icon */
}

/* ... other styles ... */
