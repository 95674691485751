/* TopBar.css */

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  box-sizing: border-box;
  z-index: 1000; /* Ensures the top bar stays on top of other elements */
}

/* Styles for the logo image */
.logo {
  width: 40px;
  margin-right: 20px;
}

/* Styles for the 'Get1page' text */
.brand-name {
  font-size: 18px;
  font-weight: bold;
}

/* Styles for the profile image */
.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: auto; /* Pushes the image to the right */
}
